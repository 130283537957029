main {
	position: relative;
}

.no-impl {
	position: absolute;
    height: 100%;
    width: 100%;
    background-color: lightgray;
    z-index: 9999999999999;
    opacity: .5;
}

ctc, ctc ctcspan {
    pointer-events: none;
    text-decoration: none !important
}
ctc i {
    display: none;
}

.clickToCall {
    display: none;
}

.clickToCall ctc ctcspan {
    pointer-events: auto;
    
}